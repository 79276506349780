import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import 'moment-timezone';
import { ScheduleMeetingService } from './schedule-meeting.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { resourceSlot, serviceDetails, slot } from './schedule-meeting.model';

@Component({
  selector: 'app-schedule-meeting',
  templateUrl: './schedule-meeting.component.html',
  styleUrls: ['./schedule-meeting.component.scss']
})
export class ScheduleMeetingComponent implements OnInit {
  serviceDetails !: serviceDetails;
  jobDurationControl = new FormControl('', Validators.required);
  zipcodeControl = new FormControl('', Validators.required);
  viewTypeControl = new FormControl('', Validators.required); 
  jobDurationControlInvalid : boolean = false;
  zipcodeControlInvalid : boolean = false;
  viewTypeControlInvalid :boolean = false;
  selectedDate!: string;
  displayDay !: string;
  displayDate !: string;
  availableSlots : Array<any> = [];
  availableUsers : Array<any> = [];
  slots: Array<any> = [];
  resourceSlots : Array<resourceSlot> = []
  minDate = new Date();
  job_duration !: number;
  team_uid !: string;
  timeslotScroll : string = '0';
  tz = moment.tz.guess();
  JobDuration = [
    { duration : '30 mins', value : '30'}
  ];
  viewType=[
    {type:'slotView', value:'Slot View'}
  ]
  checkMeetingDetails(selectedDate:string){
    if(this.jobDurationControl.invalid){
      this.jobDurationControlInvalid = true;
      this.selectedDate = '';
    }
    else if(this.zipcodeControl.invalid){
      this.zipcodeControlInvalid = true;
      this.selectedDate = '';
    }
    else if(this.viewTypeControl.invalid){
      this.viewTypeControlInvalid = true;
      this.selectedDate = '';
    }
    else if(this.jobDurationControl.valid && this.zipcodeControl.valid && this.viewTypeControl.valid){
      if(this.serviceDetails){
        this.serviceDetails.slot ={
          end_time: '',
          start_time: '',
          user_uid : '',
          users: [],
          users_available: 0
        } 
      }
      this.timeslotScroll = '0';
      this.getSelectedDate(selectedDate); 
      this.getSlots(selectedDate)
    }
  }
  getSelectedDate(select: string) {
    if(select){
      this.displayDay = moment(select).format('dddd')+', ';
      this.displayDate = moment(select).format('D')+' '+moment(select).format('MMMM') +' ' + moment(select).format('YYYY');
    }
  }
  getTeam(){
    this.scheduleMeetingService.getTeams()
    .then((res:any)=>{
      this.team_uid = res.team_uid;
    }).catch(err=>{
      console.log("Error Loading Teams",err)
    })
  }
  getSlots(select: string){
    if(select){
      this.spinner.show();
      let from_date = moment(select).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
      let to_date = moment(select).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
      let from_date_utc = moment(from_date, "YYYY-MM-DDTHH:mm:ss").utc().format('YYYY-MM-DD HH:mm:ss');
      let to_date_utc = moment(to_date, "YYYY-MM-DDTHH:mm:ss").utc().format('YYYY-MM-DD HH:mm:ss');
      this.scheduleMeetingService.getSlots(from_date_utc,to_date_utc,this.jobDurationControl.value,this.zipcodeControl.value,this.team_uid)
      .then(res=>{
        this.availableUsers = res['data'].users;
        this.availableSlots = [];
        res['data'].availability.forEach((slots: { date: string; slots: Array<slot>},index: number) => {
          if(slots.slots.length>0){
            slots.slots.forEach(slot => {
              if(moment.utc(slot.start_time).tz(this.tz).format('YYYY-MM-DD') == moment(select).format('YYYY-MM-DD')){
                this.availableSlots.push(slot)
              }
            });
          }
          if(index == res['data'].availability.length - 1){
            this.displaySlots();
            this.spinner.hide();
          }
        });
      }).catch(err => {
        console.log("Error Loading Slots",err)
        this.spinner.hide();
      });
    }
  }
  displaySlots(){
    if(this.viewTypeControl.value == 'resourceView'){
      this.resourceSlots = [];
      this.availableUsers.forEach(user=>{
        let userSlot : Array<slot> = [];
        this.availableSlots.forEach(slot=>{
          if(moment(slot.start_time).isAfter(moment()) && slot['users'].includes(user.user_uid)){
            userSlot.push(slot)
          }
        })
        let obj = {
          user_uid : user.user_uid,
          slots : userSlot.length>0?userSlot:[]
        }
        this.resourceSlots.push(obj)
      })
    }
    else{
      this.slots = [];
      for(let slot of this.availableSlots){
        if(moment(slot.start_time).isAfter(moment())){
          this.slots.push(slot);
        }
      }
    }
    setTimeout(() =>{
      document.querySelector('.demo-inline-card-timeslot')!.scrollTop =  parseInt(this.timeslotScroll || '0')
    },100)
  }
  formatTime(dateTime: any){
    return moment.utc(dateTime).tz(this.tz).format('hh:mm A');
  }
  selectSlot(slot : any, user_uid:string){
    slot['user_uid'] = user_uid;
    let serviceDetails ={
      job_duration : this.jobDurationControl.value,
      zipcode : this.zipcodeControl.value,
      view_type : this.viewTypeControl.value,
      team_uid : this.team_uid,
      slot : slot,
      day:this.displayDay,
      date : this.displayDate,
      selectedDate : this.selectedDate
    }
    let timeslotCardScroll = document.querySelector('.demo-inline-card-timeslot')!.scrollTop || 0;
    localStorage.setItem('serviceDetails',JSON.stringify(serviceDetails));
    localStorage.setItem('timeslot-card-scroll',timeslotCardScroll.toString());
    this.router.navigate(['/get_details'])
  }
  constructor(private scheduleMeetingService: ScheduleMeetingService, private spinner : NgxSpinnerService, private router : Router) {
    if(localStorage.getItem('serviceDetails')){
      this.spinner.show()
    }
    else{
      this.getTeam()
    }
    this.timeslotScroll = localStorage.getItem('timeslot-card-scroll') || '0';
    this.serviceDetails = JSON.parse(localStorage.getItem('serviceDetails')||'{}');
    this.jobDurationControl.setValue(this.serviceDetails.job_duration || '');
    this.zipcodeControl.setValue(this.serviceDetails.zipcode || '');
    this.viewTypeControl.setValue(this.serviceDetails.view_type || '')
    this.team_uid = this.serviceDetails.team_uid;
    this.selectedDate = this.serviceDetails.selectedDate || '';
    this.displayDay = this.serviceDetails.day || '';
    this.displayDate = this.serviceDetails.date || '';
    if(this.jobDurationControl.valid && this.zipcodeControl.valid){
      this.getSelectedDate(this.selectedDate); 
      this.getSlots(this.selectedDate)
    }
    else{
      this.spinner.hide();
    }
  }

  ngOnInit(): void {
  }

}
