<div class="d-flex justify-content-center align-items-center outer_div bg_grey">
    <mat-card class="demo-inline-card">
      <img class="mat-card-xs-image mb-4" src="assets/images/services_img.png">
        <!-- <mat-card-subtitle>Peer Richelsen</mat-card-subtitle> -->
        <mat-card-title>Schedule your Service</mat-card-title>
      <mat-card-content>
        <mat-list>
          <mat-list-item>
            <div class="d-flex">
              <mat-icon matListIcon [inline]="true" class="text-muted">watch_later</mat-icon>
              <!-- <p matLine class="card_list_item text-muted"> 30 minutes</p> -->
              <div>
                <select [formControl]="jobDurationControl" (change)="selectedDate='';availableSlots=[]" class="form-control text-muted" id="job_duration">
                  <option value="" disabled="true">Choose Job Duration</option>
                  <option *ngFor="let duration of JobDuration; let i = index" [value]="duration.value">{{duration.duration}}</option>
                </select>
                <span class="text-danger error-text" *ngIf="jobDurationControl.invalid && jobDurationControlInvalid">Please select the Job Duration</span>
              </div>
            </div>
          </mat-list-item>
          <mat-list-item>
            <div class="d-flex">
              <mat-icon matListIcon [inline]="true" class="text-muted">location_on</mat-icon>
              <div class="form-group">
                <input [formControl]="zipcodeControl" (change)="selectedDate='';availableSlots=[]" class="form-control text-muted" id="zipcode" placeholder="Enter Zipcode">
                <span class="text-danger error-text" *ngIf="(zipcodeControl.invalid || zipcodeControl.pristine) && zipcodeControlInvalid">Please Enter Zipcode</span>  
              </div>
            </div>
          </mat-list-item>
          <mat-list-item>
            <div class="d-flex">
              <mat-icon matListIcon [inline]="true" class="text-muted">view_list</mat-icon>
              <!-- <p matLine class="card_list_item text-muted"> 30 minutes</p> -->
              <div>
                <select [formControl]="viewTypeControl" (change)="displaySlots()" class="form-control text-muted" id="view_type">
                  <option value="" disabled="true">Choose type of view</option>
                  <option *ngFor="let view of viewType; let i = index" [value]="view.type">{{view.value}}</option>
                </select>
                <span class="text-danger error-text" *ngIf="viewTypeControl.invalid && viewTypeControlInvalid">Please select the type of View</span>
              </div>
            </div>
          </mat-list-item>
        </mat-list>
        <!-- <p>A quick screen share demo or longer conversation</p> -->
      </mat-card-content>
    </mat-card>
    <mat-card class="demo-inline-calendar-card">
      <mat-calendar [selected]="selectedDate" startView="month" [startAt]="selectedDate" [minDate]="minDate" (selectedChange)="selectedDate = $event; checkMeetingDetails(selectedDate)"></mat-calendar>
    </mat-card>
    <mat-card class="demo-inline-card-timeslot" [hidden] = "!selectedDate || availableSlots.length==0" [ngClass]="{'resource-timeslot':viewTypeControl.value == 'resourceView'}">
      <div class="mb-2">
        <strong>{{displayDay}}</strong><span class="text-muted">{{displayDate}}</span>
      </div>
      <div *ngIf="viewTypeControl && viewTypeControl.value == 'slotView' && slots.length == 0" class="d-flex flex-column justify-content-center align-items-center" id="no_slots_div">
        <span class="material-icons mb-4" id="no_slots">alarm_off</span>
        <span>No Slots available!</span>
      </div>
      <div *ngIf="viewTypeControl && viewTypeControl.value == 'slotView'">
        <button type="button" class="btn btn-outline-dark col-lg-12 mb-3" *ngFor="let slot of slots, let i = index" [ngClass]="{'selectedSlot' : serviceDetails.slot.start_time == slot.start_time}" (click)="selectSlot(slot, slot.users[0])">{{formatTime(slot.start_time)}} - {{formatTime(slot.end_time)}}</button>
      </div>
      <div *ngIf="viewTypeControl && viewTypeControl.value == 'resourceView'">
        <div *ngFor="let user of availableUsers, let i = index">
          <strong>{{user.first_name}} {{user.last_name}}</strong>
          <div *ngIf="resourceSlots[i].slots.length > 0 && user.user_uid == resourceSlots[i].user_uid"  class="mt-3">
            <button type="button" class="resource-timeslot-btn btn btn-outline-dark col-lg-6 mb-3" *ngFor="let slot of resourceSlots[i].slots, let index = index" [ngClass]="{'selectedSlot' : serviceDetails.slot.start_time == slot.start_time && serviceDetails.slot.user_uid && serviceDetails.slot.user_uid == resourceSlots[i].user_uid}" (click)="selectSlot(slot, resourceSlots[i].user_uid)">{{formatTime(slot.start_time)}} - {{formatTime(slot.end_time)}}</button>
          </div>
          <div *ngIf="resourceSlots[i].slots.length == 0" class="d-flex flex-column justify-content-center align-items-center mt-2 mb-2" id="no_slots_div">
            <span class="material-icons mb-4" id="no_slots">alarm_off</span>
            <span>No Slots available!</span>
          </div>
        </div>
      </div>
    </mat-card>
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
      <!-- <p style="color: white">Please Wait. </p>   -->
    </ngx-spinner>
</div>
